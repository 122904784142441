export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "40px",
    order: 0,
    icon: "fa-cog",
    class: "px-2",
    cellClass: "px-2",
  },
  {
    text: "Name",
    value: "name",
    width: "150px",
    order: 1,
    hidable: false,
    hidden: false,
  },
  {
    text: "Number",
    value: "number",
    width: "100px",
    order: 2,
    hidable: false,
    hidden: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 3,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Creator",
    value: "createdBy",
    width: "130px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updater",
    value: "updatedBy",
    width: "130px",
    order: 6,
    hidable: true,
    hidden: false,
  },
];
