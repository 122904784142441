<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Equipment Categories"
      subtitle="Browse and Manage your Categories!"
      :badge="this.total"
    />
    <v-row class="mt-0 mb-1" justify="space-between">
      <v-col md="3" sm="12" cols="12">
        <v-btn color="info" @click="add" v-if="$has(perms.EquipmentCategories.Create)"
          ><i class="fal fa-plus mr-2"></i>Add Category</v-btn
        >
      </v-col>
      <v-col
        md="9"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <filter-manager
          ref="filterManager"
          v-model="selectedFilters"
          :options.sync="options"
        ></filter-manager>
        <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>

    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :loading="loadingStates.table"
      :item-class="
        (item) => {
          return item.newCategory ? 'new-category-animation' : '';
        }
      "
      @click:row="rowClicked"
      class="elevation-2 categories-table table-sticky-header-exclude-footer"
      :hide-default-footer="printing"
      :disable-pagination="printing"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-row align-content="center" justify="start" no-gutters style="flex: none">
          <v-col sm="auto" class="d-flex align-center mr-2">
            <i :class="'equipment-icon fad ' + item.icon"></i>
          </v-col>
          <v-col class="d-flex align-center col-auto">
            <div class="d-flex align-center">
              {{ item.name }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.number`]="{ item }">
        <i class="fas fa-hashtag fs-12px mr-1"></i>{{ item.number }}
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" dateonly></dater>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="update(item.id)" v-if="$has(perms.EquipmentCategories.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="del(item)" v-if="$has(perms.EquipmentCategories.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <edit-equipment-category
      @save="onCategorySaved"
      @close="onCategoryClosed"
      @delete="onCategoryDeleted"
      ref="editCategory"
    ></edit-equipment-category>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import ApiService from "../services/categories-service";
import categoriesHeader from "../config/tables/category.header";
import EditEquipmentCategory from "../components/EditEquipmentCategory.vue";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import { UserFilterSettings } from "../../Shared/components/FilterManager/FilterSettings";

export default {
  components: {
    EditEquipmentCategory,
    FilterManager,
  },
  data() {
    return {
      perms: perms,
      printing: false,
      selectedFilters: [],
      storageKey: "EquipmentCategories",
      openCategoryInEditMode: false,
      paramId: null,
      entities: [],
      selected: {},
      total: 0,
      inventoryTimerId: null,
      defaultHeaders: [],
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        page: 1,
        sortBy: ["name"],
        sortDesc: [false],
      },
      lastSentOptions: null,
      storedSearch: null,
      loadingStates: {
        table: false,
      },
      headers: categoriesHeader,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.$moveable(document.querySelector(".categories-table .v-data-table__wrapper"));

    this.$refs.mainSearch.focus();
    this.checkRouteQuery();
    this.checkForSingleCategoryRoute(this.$route);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      this.selectedFilters = [createdByFilterSettings, updatedByFilterSettings];
    },
    checkRouteQuery(evt) {
      // this.$log("checkRoute", this.$route.query.q, evt);
      if (this.$route.query.q) this.options.search = this.$route.query.q;
      //  else this.options.search = null;
    },
    updateRouterLink() {
      if (this.$route.query.q == this.options.search) return;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          path: "/equipment-categories",
          query: { q: this.options.search },
          replace: true,
        });
      } else this.$router.push({ path: "/equipment-categories", replace: true });
    },
    onCategoryClosed() {
      const path = `/equipment-categories`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    getData() {
      this.$backToTop(0, document.querySelector(".categories-table .v-data-table__wrapper"));
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 1;
      // this.$log("######################## GET DATA ########################");
      // this.$log("optionsToSend:", optionsToSend);
      this.storedSearch = optionsToSend.search;
      this.loadingStates.table = true;
      ApiService.query(this.$clean(optionsToSend, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("query:", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    del(category) {
      var equip = this.entities.find((e) => e.id == category.id);
      if (equip == null) return;
      this.$dialog
        .warning({
          text: ` Are you sure you want to delete this Category?<br/><h4>${category.name}</h4>`,
          title: `Delete Category?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(category.id)
                  .then((resp) => {
                    this.onCategoryDeleted(category.id);
                    this.$dialog.notify.success("Category deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting Category!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onCategoryDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    onCategorySaved(categoryData, isNewCategory) {
      if (isNewCategory) {
        this.entities.unshift(categoryData);
      } else {
        this.updateArr(this.entities, categoryData);
      }
    },
    getDataDebounced() {
      if (this.inventoryTimerId == null) {
        this.inventoryTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.inventoryTimerId);

      // delay new call 400ms
      this.inventoryTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    add() {
      this.selected = {};
      this.$refs.editCategory.open(null);
    },
    update(id) {
      this.selected = { id: id };
      this.openCategoryInEditMode = true;
      setTimeout(() => {
        const path = `/equipment-categories/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    view(id) {
      this.openCategoryInEditMode = false;
      setTimeout(() => {
        const path = `/equipment-categories/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    openCategory(id) {
      setTimeout(() => {
        this.$refs.editCategory.open(id, this.openCategoryInEditMode);
        this.openCategoryInEditMode = false;
      }, 50);
    },
    rowClicked(row) {
      this.view(row.id);
    },
    checkForSingleCategoryRoute(route) {
      if (route.params && route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.openCategory(this.paramId);
      } else this.paramId = null;
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        var test_ToSend = this.cloneDeep(this.options);
        var test_LastSent =
          this.lastSentOptions == null
            ? this.cloneDeep(this.options)
            : this.cloneDeep(this.lastSentOptions);
        test_ToSend.page = null;
        test_LastSent.page = null;
        if (!this.isEqual(test_ToSend, test_LastSent) && this.options.page != 1) {
          this.options.page = 1;
        } else {
          this.getDataDebounced();
        }
        this.lastSentOptions = this.cloneDeep(this.options);
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        this.checkRouteQuery();
        if (
          oldRoute.name == "equipment-categories" &&
          newRoute.name == "single-equipment-category"
        ) {
          this.openCategory(newRoute.params.id);
          return;
        }

        if (
          oldRoute.name == "single-equipment-category" &&
          newRoute.name == "single-equipment-category"
        ) {
          this.openCategory(newRoute.params.id);
          return;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.new-category-animation {
  animation: FlashBackgroundAnim 1s;
  animation-iteration-count: 5;
}

@keyframes FlashBackgroundAnim {
  0% {
    background: white;
  }
  50% {
    background: rgba($orange-base, 0.16);
  }
  100% {
    background: white;
  }
}

.categories-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.v-list-item__title {
  font-size: 14px !important;
}
</style>
